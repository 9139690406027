var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B 6B"
    },
    B: {
      "2": "C K L G M N O",
      "1025": "f g h i j k l m n o p q c H",
      "1537": "P Q R S T U V W X Y Z a b e"
    },
    C: {
      "2": "7B",
      "932": "0 1 2 3 4 5 6 7 8 9 qB I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB 8B 9B",
      "2308": "bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB"
    },
    D: {
      "2": "I r J D E F A B C K L G M N O s t u",
      "545": "0 1 2 3 4 5 6 7 8 9 v w x y z AB BB CB DB EB FB GB HB IB",
      "1025": "f g h i j k l m n o p q c H uB vB AC BC",
      "1537": "JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e"
    },
    E: {
      "1": "pB 3B JC KC",
      "2": "I r J CC wB DC",
      "516": "B C K L G nB oB yB HC IC zB 0B 1B 2B",
      "548": "F A GC xB",
      "676": "D E EC FC"
    },
    F: {
      "2": "F B C LC MC NC OC nB 4B PC oB",
      "513": "7",
      "545": "0 1 2 3 4 5 G M N O s t u v w x y z",
      "1537": "6 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b"
    },
    G: {
      "1": "pB 3B",
      "2": "wB QC 5B RC SC",
      "516": "hC iC jC zB 0B 1B 2B",
      "548": "VC WC XC YC ZC aC bC cC dC eC fC gC",
      "676": "E TC UC"
    },
    H: {
      "2": "kC"
    },
    I: {
      "2": "qB I lC mC nC oC 5B",
      "545": "pC qC",
      "1025": "H"
    },
    J: {
      "2": "D",
      "545": "A"
    },
    K: {
      "2": "A B C nB 4B oB",
      "1025": "d"
    },
    L: {
      "1025": "H"
    },
    M: {
      "2308": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1537": "rC"
    },
    P: {
      "545": "I",
      "1025": "2C 3C 4C",
      "1537": "sC tC uC vC wC xB xC yC zC 0C 1C pB"
    },
    Q: {
      "1537": "yB"
    },
    R: {
      "1537": "5C"
    },
    S: {
      "932": "6C"
    }
  },
  B: 5,
  C: "Intrinsic & Extrinsic Sizing"
};
export default exports;